import Header from '../comps/Header.js';
import Heiro from '../comps/Heiro.js';
import Slider from '../comps/Slider.js';
import Slider2 from '../comps/Slider2.js';
import Target from '../comps/Target.js';
import TypeS from '../comps/TypeS.js';
import FAQ from '../comps/FAQ.js';
import Footer from '../comps/Footer.js';
import Story from '../comps/Story.js';


const Index = () => {
    return (
        <div className="bg-gradient-to-r from-[#E0E7EE] to-[#EFF6F6] ">

          <Heiro />

          {/* <div className="md:hidden">
              <Slider />
          </div> */}



          {/* slider 2  */}
          <div className="flex flex-col items-center justify-center mt-44">


  <div className="text-6xl">Driven</div>
  <div className="text-4xl font-semibold text-gray-900 pt-3">by BE SEEN</div>

</div>



             <Slider2 />


          <Target />
          {/* <Story /> */}
          <TypeS />
          <FAQ />









        </div>
    )
}

export default Index;