import React, { useEffect, useRef } from "react";
import gsap from "gsap";

const AnimatedText = () => {
  const words = ["Drivers", "راننده‌ها", "سائق", "ड्राइवरों"];
  const containerRef = useRef(null);
  const wordIndex = useRef(0);

  useEffect(() => {
    const container = containerRef.current;

    const updateWord = () => {
      wordIndex.current = (wordIndex.current + 1) % words.length;
      gsap.to(container, {
        y: "-100%", // انیمیشن به سمت بالا
        duration: 0.5,
        onComplete: () => {
          container.style.y = "100%"; // بازگشت به حالت اولیه
          container.textContent = words[wordIndex.current];
          gsap.to(container, { y: "0%", duration: 0.5 });
        },
      });
    };

    const interval = setInterval(updateWord, 3000); // هر 3 ثانیه تغییر کند

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="text-xl overflow-hidden h-[1.5em]">
      <div
        ref={containerRef}
        className="inline-block relative text-[15px] md:text-[17px] font-light"
        style={{ transform: "translateY(0)" }}
      >
        drivers
      </div>
    </div>
  );
};

export default AnimatedText;
