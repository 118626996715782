import React, { useEffect } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import ScrollTrigger from "gsap/ScrollTrigger";
import './Slider.css';

gsap.registerPlugin(ScrollTrigger, useGSAP);

const Slider2 = () => {
    useGSAP(() => {
        const slider = document.querySelector(".slider");
        const sections = gsap.utils.toArray(".slider section");

        let scrollTween = gsap.to(sections, {
            xPercent: -200,
            ease: "none",
            scrollTrigger: {
                trigger: slider,
                pin: true,
                scrub: 1,
                snap: 1 / (sections.length - 1),
                end: () => "+=" + document.querySelector(".slider").offsetWidth,
            },
        });

        const commonScrollTrigger = (triggerElement) => ({
            containerAnimation: scrollTween,
            trigger: triggerElement,
            start: "left center",
            toggleActions: "play none none reverse",
        });

        sections.forEach((section) => {
            gsap.from(section.querySelector("h3"), {
                yPercent: 135,
                scrollTrigger: commonScrollTrigger(section.querySelector("h3")),
            });

            gsap.from(section.querySelector("p"), {
                yPercent: 50,
                opacity: 0,
                scrollTrigger: commonScrollTrigger(section.querySelector("p")),
            });

            gsap.from(section.querySelector("img"), {
                opacity: 0,
                y: 50,
                scrollTrigger: commonScrollTrigger(section),
            });

            gsap.to("body", {
                backgroundColor: section.dataset.bgColor,
                scrollTrigger: commonScrollTrigger(section),
            });

            gsap.from(section.querySelector(".right-col"), {
                scaleY: 0,
                transformOrigin: "bottom",
                scrollTrigger: commonScrollTrigger(section),
            });
        });

        gsap.to(".inner", {
            scaleX: 1,
            scrollTrigger: { scrub: 0.3 },
        });
    }, []);
 
    return (
        <div>
            <div className="progress">
                <div className="inner"></div>
            </div>

            <div className="overflow-hidden ">

            <div className="slider">
            <section>
                    <main>
                        <div className="content">
                        <div className="mask text-left">

                            <h3 className="text-3xl font-mediu">Domivio</h3>
                            </div>

                            <p className="text-md font-light pt-3 text-left">
                                BE SEEN transformed Domivio’s marketing with 
                                innovative vehicle advertising. Our 
                                eye-catching car wraps turned city 
                                streets into dynamic billboards, showcasing 
                                property listings and capturing the attention 
                                of potential homebuyers. This campaign boosted 
                                brand visibility and set a new standard for 
                                local real estate marketing.
                            </p>
                        </div>
                    </main>
                    <div className="right-col red"></div>
                    <img src="/img/cars1.png" className="img" alt="Lifting shoe" />
                </section>

                <section className="" data-pin="true" data-bg-color="white">
                    <main>
                        <div className="content">
                        <div className="mask text-left">
                        <h3 className="text-3xl font-mediu">TerraLyn</h3>
                            </div>

                            <p className="text-md font-light pt-3 text-left">
                                Be Seen targeted TerraLyn’s ideal audience by 
                                wrapping cars near universities, coffee shops, 
                                and co-working spaces. This precision campaign 
                                turned commutes into travel inspiration, 
                                placing TerraLyn’s brand where wanderlust-driven 
                                millennials live, work, and dream.
                            </p>
                        </div>
                    </main>
                    <div className="right-col red"></div>
                    <img src="/img/cars2.png" className="img" alt="Lifting shoe" />
                </section>

                <section className="" data-pin="true" data-bg-color="#E9E9E9">
                    <main>
                        <div className="content">
                        <div className="mask text-left">
                                <h3 className="text-3xl font-mediu">Homina</h3>
                            </div>

                            <p className="text-md font-light pt-3 text-left">
                                Be Seen strategically targeted Homina’s 
                                audience by wrapping cars near residential 
                                neighborhoods, shopping centers, and family hubs.
                                This campaign seamlessly connected Homina’s 
                                brand with homeowners, turning daily errands 
                                into reminders of quality, comfort, and the 
                                perfect home essentials.
                            </p>
                        </div>
                    </main>
                    <div className="right-col dark"></div>
                    <img src="/img/cars3.png" className="img" alt="Lifting shoe" />
                </section>
                </div>
            </div>
        </div>
    );
};

export default Slider2;
