import SubWho from './Subwho.js';
import React, { useState, useEffect } from 'react';


const Firstwho = ({index, id, cases}) => {
  const [desShow, setdesShow] = useState(false);

    const defShower = (e) => {
        setdesShow(true);
        console.log(e)
      };

      const defCloser = () => {
        setdesShow(false);
      };

    return (

        <div>
        <div key={index} className={` border-b w-full  flex flex-col  max-md:px-7 p-20 max-md:pb-7 justify-between group ${cases.zoj ? "md:flex-row-reverse" : "md:flex-row"} ${desShow ? "opacity-25" : ""} `}>
          <img className="w-[350px] " src={cases.img} alt="" />
          <div className="flex flex-col justify-center text-left gap-4">
            <h3 className="text-gray-700 text-left text-2xl font-bold">{cases.title}</h3>
            <p className="max-w-md">{cases.description}</p>
            <div className="flex flex-col md:ml-3 mt-3 p-2 text-gray-500 rounded-3xl">
              <ul className="list-disc">
                <li className="pb-3 transition-all duration-700">{cases.item1}</li>
                <li className="pb-3 transition-all duration-700">{cases.item2}</li>
                <li className="transition-all duration-700">{cases.item3}</li>
              </ul>
            </div>
            <div className="flex justify-start items-center">
              
            </div>
          </div>
        </div>
        </div>

    )
}

export default Firstwho;