import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./gsap.css";

const Loading = () => {
  const loadingTextRef = useRef(null);
  const circleRef = useRef(null);
  const contRef = useRef(null);

  useEffect(() => {
    // انیمیشن متن لودینگ


    // انیمیشن دایره
    gsap.to(circleRef.current, {
      scale: 1.5,
      duration: 2,
      ease: "power1.inOut",

      opacity: 0,
    });

    gsap.to(contRef.current, {

            duration: 3,
            ease: "power1.inOut",

            opacity: 0,
      });


      gsap.to(circleRef.current, {
        delay: 3,
        display: "none",
      });
  
      gsap.to(contRef.current, {
        delay: 3,
        display: "none",
        });


  }, []);

  return (

    <div ref={contRef} className="loading-container">
      <div className="loading-circle" ref={circleRef}></div>

    </div>

  );
};

export default Loading;
