import React, { useState } from "react";

const Target = () => {

    const [tab, setTab] = useState(0);



    return (
        <div className="flex flex-col items-center">




        <div className="mt-10">
  <div className="text-6xl font-thin">Choose how</div>
  <div className="text-6xl font-semibold text-gray-900 pt-6">to be SEEN</div>
  <div className="text-xl font-thin text-gray-600 mt-7">Target Your Audience Precisely</div>

</div>



<div className="flex flex-col md:flex-row-reverse justify-center items-center container mt-44">

    <div className="flex flex-col flex-[0.5] items-center justify-center">

        { tab == 0 && 

<div>

<img className="-mt-28 w-[350px]" src="./img/tar4.svg"></img>
<div className="border border-gray-200 rounded-2xl text-left p-6">
        This ensures your ads are seen in key areas where your <br></br>
        target audience lives or visits, maximizing visibility <br></br>
        and engagement. It also helps optimize <br></br>
        costs by targeting effectively.




</div>
</div>

        }

{ tab == 1 && <div>

<img className="-mt-28 w-[400px]" src="./img/tar2.svg"></img>
<div className="border border-gray-200 rounded-2xl text-left p-6">
        This ensures your ads resonate with professionals based <br></br>
        on their career paths, maximizing visibility and engagement. <br></br>
        It also helps optimize costs by targeting effectively.


</div>
</div>

}

{ tab == 2 && <div>

<img className="-mt-28 w-[350px]" src="./img/tar3.svg"></img>
<div className="border border-gray-200 rounded-2xl text-left p-6">
        This ensures your ads appeal to the right age group,<br></br> 
        delivering messages that align with their preferences, <br></br>
        maximizing visibility and engagement. It also helps <br></br>
        optimize costs by targeting effectively.



</div>
</div>

}

{ tab == 3 && <div>

<img className="-mt-28 w-[500px]" src="./img/tar1.svg"></img>
<div className="border border-gray-200 rounded-2xl text-left p-6">
This ensures your ads reach them at the right moment <br></br>
during their daily commutes, maximizing visibility and<br></br>
engagement. It also helps optimize costs by <br></br>
targeting effectively.



</div>
</div>

}

    </div>


    <div className="flex flex-col items-center flex-[0.5] gap-4 ">
        <div className="text-left w-[40%] max-md:mt-7">Target by</div>
        <div onClick={()=> setTab(0)} className={`md:w-[40%] md:pl-5 max-md:w-[200px] border border-gray-300 py-4 flex justify-center md:justify-start items-center rounded-2xl hover:bg-gray-900 hover:text-white cursor-pointer transition-all ${tab == 0 ? "bg-gray-900 text-white" : ""}`}>Neighborhood</div>
        <div onClick={()=> setTab(1)} className={`md:w-[40%] md:pl-5 max-md:w-[200px] border border-gray-300 py-4 flex justify-center md:justify-start items-center rounded-2xl hover:bg-gray-900 hover:text-white cursor-pointer transition-all ${tab == 1 ? "bg-gray-900 text-white" : ""}`}>Occupation</div>
        <div onClick={()=> setTab(2)} className={`md:w-[40%] md:pl-5 max-md:w-[200px] border border-gray-300 py-4 flex justify-center md:justify-start items-center rounded-2xl hover:bg-gray-900 hover:text-white cursor-pointer transition-all ${tab == 2 ? "bg-gray-900 text-white" : ""}`}>Age Range</div>
        <div onClick={()=> setTab(3)} className={`md:w-[40%] md:pl-5 max-md:w-[200px] border border-gray-300 py-4 flex justify-center md:justify-start items-center rounded-2xl hover:bg-gray-900 hover:text-white cursor-pointer transition-all ${tab == 3 ? "bg-gray-900 text-white" : ""}`}>Commute Time</div>

    </div>

</div>





</div>

    )
}

export default Target;