const TypeS = () => {
    return (
        <div className="mt-48">

        <div className="mt-10">
  <div className="text-6xl font-thin">Join (to)</div>
  <div className="text-6xl font-semibold text-gray-900 pt-4">BE SEEN</div>

</div>

                    <div className="h-[700px] md:h-[600px] mt-10 md:container md:p-24 ">
            <section className="h-full flex flex-col lg:flex-row">
                {/* Driver Box */}
                <a
                    className=" md:rounded-[50px_0px_0px_50px] flex-1 group bg-cover relative flex items-end justify-start  bg-gray-300
                    text-white text-2xl font-bold  transition-transform transform hover:cale-x-105 hover:bg-gray-400"
                href={'/drivers'} style={{backgroundImage: `url("/img/driver.jpg")` , backgroundRepeat: 'no-repeat',}}>
                    <div className="w-full h-full md:rounded-[50px_0px_0px_50px] bg-gray-900 opacity-40 absolute group-hover:opacity-0 transition-all  "></div>
                    <span className="pb-10 md:pl-10 pl-3 text-3xl">
                    Are you a driver ?
                    </span>
                    <svg
                        className=" shadow-xl absolute bottom-12 right-12 w-8 h-8 text-white rounded-xl group-hover:bg-white p-1 group-hover:text-black group-hover:-translate-x-2 transition-all"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4l8 8-8 8M4 12h16"
                        />
                    </svg> 
                </a>

                {/* Business Owner Box */}
                <a
                    className=" md:rounded-[0px_50px_50px_0px]  flex-1 group bg-cover relative flex items-end justify-start mr-1 bg-gray-300
                    text-white text-2xl font-bold  transition-transform transform hover:cale-x-105 hover:bg-primary"
                href={'/advertisers'} style={{backgroundImage: `url("/img/bs.jpeg")` , backgroundRepeat: 'no-repeat',}}>
                    <div className="w-full h-full bg-gray-900 md:rounded-[0px_50px_50px_0px] opacity-40 absolute group-hover:opacity-0 transition-all  "></div>
                    <span className="pb-10 md:pl-10 pl-3 text-3xl text-left">
                    Are you a <br></br> business owner?
                    </span>
                    <svg
                        className="shadow-xl  absolute bottom-12 right-12 w-8 h-8 text-white rounded-xl group-hover:bg-white p-1 group-hover:text-black group-hover:-translate-x-2 transition-all"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4l8 8-8 8M4 12h16"
                        />
                    </svg>
                </a>
            </section>
        </div>
        </div>
    )
}

export default TypeS;