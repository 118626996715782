import React, { useState, useEffect } from 'react';
import Firstwho from './Firstwho.js';



export default function Who() {

  const caseStudies = [
    {
      title: "Restaurants",
      description: "Be Seen supports restaurants through innovative advertising solutions:",
      item1: "Target high-traffic dining districts and food-centric neighborhoods",
      item2: "Promote seasonal menu items and limited-time offers",
      item3: "Create geo-targeted campaigns during peak dining hours",
      titleSub: "Strategic Repositioning at FortressData Systems",
      descriptionSub: "FortressData Systems used Value Aligners to guide a major strategic shift:",
      item1Sub: "Identified a high-growth market segment, leading to a successful pivot that increased market share by 10%",
      item2Sub: "Optimized resource allocation, improving overall profitability by 18%",
      item3Sub: "Guided a strategic acquisition based on pricing and market data, expanding service offerings",
      img: "/img/rs.svg",
      zoj: false,


    },
    {
      title: "Beauty Salons",
      description: "Be Seen empowers beauty businesses with strategic marketing:",
      item1: "Target neighborhoods with high concentration of target demographics",
      item2: "Generate buzz for grand openings and rebranding efforts",
      item3: "Track potential client interaction zones",
      titleSub: "Strategic Repositioning at FortressData Systems",
      descriptionSub: "FortressData Systems used Value Aligners to guide a major strategic shift:",
      item1Sub: "Identified a high-growth market segment, leading to a successful pivot that increased market share by 10%",
      item2Sub: "Optimized resource allocation, improving overall profitability by 18%",
      item3Sub: "Guided a strategic acquisition based on pricing and market data, expanding service offerings",
      img: "/img/bu.svg",
      zoj: true,


    },
    {
      title: "Real Estate Agencies",
      description: "Be Seen provides real estate companies with:",
      item1: "Location-based property advertisement",
      item2: "Targeted neighborhood marketing",
      item3: "Tracking potential client engagement zones",
      titleSub: "Strategic Repositioning at FortressData Systems",
      descriptionSub: "FortressData Systems used Value Aligners to guide a major strategic shift:",
      item1Sub: "Identified a high-growth market segment, leading to a successful pivot that increased market share by 10%",
      item2Sub: "Optimized resource allocation, improving overall profitability by 18%",
      item3Sub: "Guided a strategic acquisition based on pricing and market data, expanding service offerings",
      img: "/img/ceo.svg",
      zoj: false,


    },
    {
      title: "Educational Institutions",
      description: "Be Seen supports educational platforms through:",
      item1: "Creating awareness campaigns for specific programs",
      item2: "Promoting new courses and enrollment periods",
      item3: "Tracking student demographic engagement",
      titleSub: "Expanding Market Reach at SecureLogic Inc.",
      descriptionSub: "SecureLogic Inc. used Value Aligners to drive business development:",
      item1Sub: "Identified and successfully entered two new geographical markets, increasing revenue by 30%",
      item2Sub: "Developed a tiered pricing strategy for channel partners, growing partner network by 50%",
      item3Sub: "Improved proposal win rates by 35% with data-backed pricing and market analysis",
      img: "/img/de.svg",
      zoj: true,


    },
    {
      title: "Ridesharing Companies",
      description: "Be Seen helps ridesharing services maximize brand visibility through:",
      item1: "Strategic ad placement on driver vehicles during peak hours",
      item2: "Targeted neighborhood and event-based advertising",
      item3: "Dynamic pricing for ad spaces based on traffic and route popularity",
      titleSub: "Enhanced driver income through additional revenue streams",
      descriptionSub: "CyberShield Inc., a mid-sized cybersecurity firm, struggled with pricing inconsistencies across different markets. After implementing Value Aligners:",
      item1Sub: "Pricing optimization led to a 15% increase in overall revenue",
      item2Sub: "Reduced pricing team size by 30% while improving efficiency",
      item3Sub: "Achieved 98% accuracy in competitor price tracking, enabling real-time adjustments",
      img: "/img/pr.svg",
      zoj: false,
    },
    {
      title: "Food Delivery Services",
      description: "Be Seen supports food delivery platforms by:",
      item1: "Promoting restaurant brands during delivery routes",
      item2: "Geo-targeted advertising in high-traffic food districts",
      item3: "Tracking ad performance in real-time across different neighborhoods",
      titleSub: "Creating micro-targeted marketing campaigns for specific cuisine types",
      descriptionSub: "SecureNet Solutions used Value Aligners to guide their product strategy:",
      item1Sub: "Identified an underserved market segment, leading to a new product line with $5M in first-year revenue",
      item2Sub: "Optimized feature set based on pricing data, increasing customer satisfaction by 25%",
      item3Sub: "Reduced time-to-market for new products by 20% through data-driven decision making",
      img: "/img/ba.svg",
      zoj: true,


    },
    {
      title: "E-commerce Startups",
      description: "Be Seen enables e-commerce businesses to:",
      item1: "Increase brand awareness in urban and suburban areas",
      item2: "Develop hyper-local marketing strategies",
      item3: "Develop hyper-local marketing strategies",
      titleSub: "Empowering Sales at CyberGuard Technologies",
      descriptionSub: "CyberGuard Technologies leveraged Value Aligners to enhance their sales processes:",
      item1Sub: "Increased win rates by 30% with access to real-time competitive pricing",
      item2Sub: "Improved average deal size by 20% through data-driven upselling",
      item3Sub: "Reduced sales cycle length by 15% with more accurate initial pricing proposals",
      img: "/img/dr.svg",
      zoj: false,


    },
    {
      title: "Fitness and Wellness Centers",
      description: "Be Seen helps fitness businesses by:",
      item1: "Promoting gym locations and special offers",
      item2: "Targeting health-conscious neighborhoods",
      item3: "Tracking potential member interaction areas",
      titleSub: "Data-Driven Marketing at CyberSafe Solutions",
      descriptionSub: "CyberSafe Solutions transformed their marketing approach with Value Aligners:",
      item1Sub: "Increased marketing ROI by 40% through targeted campaigns based on pricing insights",
      item2Sub: "Improved brand positioning, leading to a 25% increase in inbound leads",
      item3Sub: "Developed a successful freemium model, increasing user base by 150% in 6 months",
      img: "/img/mr.svg",
      zoj: true,


    },
    {
      title: "Tech Startups",
      description: "Be Seen enables tech companies to:",
      item1: "Increase brand visibility in tech-hub areas",
      item2: "Target professional and innovation districts",
      item3: "Create buzz around product launches",
      titleSub: "Expanding Market Reach at SecureLogic Inc.",
      descriptionSub: "SecureLogic Inc. used Value Aligners to drive business development:",
      item1Sub: "Identified and successfully entered two new geographical markets, increasing revenue by 30%",
      item2Sub: "Developed a tiered pricing strategy for channel partners, growing partner network by 50%",
      item3Sub: "Improved proposal win rates by 35% with data-backed pricing and market analysis",
      img: "/img/ro.svg",
      zoj: false,


    },
    {
      title: "Local Retail Stores",
      description: "Be Seen helps local businesses by:",
      item1: "Targeting specific neighborhood demographics",
      item2: "Creating hyperlocal marketing campaigns",
      item3: "Tracking foot traffic and potential customer zones",
      titleSub: "Expanding Market Reach at SecureLogic Inc.",
      descriptionSub: "SecureLogic Inc. used Value Aligners to drive business development:",
      item1Sub: "Identified and successfully entered two new geographical markets, increasing revenue by 30%",
      item2Sub: "Developed a tiered pricing strategy for channel partners, growing partner network by 50%",
      item3Sub: "Improved proposal win rates by 35% with data-backed pricing and market analysis",
      img: "/img/bag.svg",
      zoj: true,


    }
  ];
  


 





  return (
    <div className="pb-16 flex flex-col items-center h-[520rem] md:h-[400rem]">

      <div  className="max-md:p-20 flex flex-row items-end justify-center w-full bg-center h-[450px] md:h-[508px] bg-[url('https://beseen-site.vercel.app/img/bgw7m.png')] md:bg-[url('https://beseen-site.vercel.app/img/bgw7.png')] bg-cover">

          <div className="text-5xl md:text-6xl text-left md:text-black text-white  rounded-3xl p-7   md:-mt-10 font-semibold font-nothing-you-could-do max-md:hidden">Who is it for ?</div>
          <div className="text-5xl md:text-6xl text-left text-white  rounded-3xl p-8 backdrop-blur-sm  md:-mt-10 font-semibold font-nothing-you-could-do md:hidden">Who is <br></br> it for ?</div>

      </div>

      <div className="container mx-auto max-w-7xl mt-14 md:mt-20 border-t px-4">
        <div className="flex flex-col pt-20 gap-12">


        {caseStudies.map((cases, index) => (
          
          <Firstwho index={index} id={index} cases={cases} />
          
        ))}



        </div>
      </div>
    </div>
  );
}
