const Heiro = () => {
    return (
        <div className="bg-cover bg-center h-[300px] md:h-[700px] grid grid-cols-3 relative 
        bg-[url('https://beseen-site.vercel.app/img/carm.png')] md:bg-[url('https://beseen-site.vercel.app/img/carh.png')]">
             <div className="absolute inset-0 bg-black opacity-0 md:opacity-0"></div>


            <div className=" ">
            </div>

            <div className="flex justify-center items-center ">

                <div className="text-center md:text-left  text-3xl md:text-7xl text-black md:hidden z-50  rounded-3xl max-md:-mb-44 ">
                        <h3 className="font-bold">want to </h3>
                        <h3 className="font-extrabold max-md:text-4xl pt-6 max-md:w-[194px]">BE SEEN!?</h3>
                </div>

            </div>

            <div className="flex justify-start items-center ">

                <div className="text-left text-3xl md:text-7xl text-black max-md:hidden">
                        <h2>want to </h2>
                        <h3 className="font-bold pt-6">BE SEEN&nbsp;!?</h3>
                </div>

            </div>


        </div>
    )
}

export default Heiro;