import AnimatedText from '../comps/AnimatedText.js';

const Header = () => {
    return (
      <div className="border-b bg-gradient-to-r from-[#E0E7EE] to-[#EFF6F6]">
        <header className="container py-8 bg-transparent">
            <div className="navbar ">
  <div className="flex gap-7 divide-x">
    <a href="/" className=" text-xl">
      <img className="w-[70px]" src="/img/logot.png"></img>
    </a>
    <div><span className=""></span></div>
  </div>
  <div className="flex-none navbar-start md:pl-6 ">
    <div className="dropdown">
      <div tabIndex={0} role="button" className="btn btn-ghost md:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 6h16M4 12h8m-8 6h16" />
        </svg>
      </div>
      <ul
        tabIndex={0}
        className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow !text-gray-800">
        <li><a href="/">Home</a></li>
        <li><a href="/about">About</a></li>
        {/* <li><a>Stories</a></li> */}
        <li><a href="/whois-it-for">Who is it for</a></li>

      </ul>
    </div>
    {/* desktop s  */}
    <ul className="menu menu-horizontal px-1 text-[17px] font-light opacity-60 hidden md:flex">
    <li><a href="/">Home</a></li>
    <li><a href="/about">About</a></li>
      {/* <li><a>Stories</a></li> */}
      <li><a href="/whois-it-for">Who is it for</a></li>
      {/* <li>
        <details>
          <summary>Parent</summary>
          <ul className="bg-base-100 rounded-t-none p-2">
            <li><a>Link 1</a></li>
            <li><a>Link 2</a></li>
          </ul>
        </details>
      </li> */}
    </ul>
    {/* desktop e  */}
  </div>
  <div className="navbar-end">
    <a href="/drivers" className=" max-md:bg-gray-900 max-md:py-2 max-md:rounded-xl max-md:text-white text-black md:border-r md:border-gray-300  hover:text-black text-xs w-28 font-vazir max-md:hover:bg-gray-300 transition-all cursor-pointer flex justify-center items-center">
        <AnimatedText />
    </a>
    <a href="/advertisers" className=" text-black max-md:hidden rounded-xl ml-4   text-sm w-28 font-vazir transition-all cursor-pointer flex justify-center items-center">
    Advertisers
    </a>
  </div>
</div>
        </header>
        </div>
    )
}

export default Header;