const FAQ = () => {
    return (
        <div className="mt-48 max-md:mx-10">

<div className="mt-10">

  <div className="text-6xl font-semibold text-gray-900 pt-4">FAQ</div>

</div>

        <div className="container  bg-white p-10 md:p-20 rounded-[50px]  md:w-[70%] mt-28">
          <div className="collapse collapse-plus bg-white border-b rounded-none">
  <input type="radio" name="my-accordion-3" defaultChecked />
  <div className="collapse-title font-medium text-[22px] text-gray-700 text-left">
  Who are your drivers?
    </div>
  <div className="collapse-content">
    <p className="text-left text-lg max-w-[90%] opacity-70 ">
    Are they limited to rideshare drivers? Our network includes rideshare drivers using platforms like Uber and Lyft, delivery couriers for services such as DoorDash, Uber Eats, and skip the dishes, as well as everyday commuters who are looking to earn extra income on their regular routes. Join our growing community today and start maximizing your earnings!
    </p>
  </div>
</div>
<div className="collapse collapse-plus bg-white border-b rounded-none">
  <input type="radio" name="my-accordion-3" />
  <div className="collapse-title font-medium text-[22px] text-gray-700 text-left">
  What is the minimum duration for a Be Seen campaign?
  </div>
  <div className="collapse-content">
  <p className="text-left text-lg max-w-[90%] opacity-70">
  The shortest duration for a Be Seen campaign is 4 weeks. However, for maximum impact and optimal visibility, we recommend considering longer campaigns. Extended campaigns not only ensure sustained exposure but also allow for deeper brand recognition within your target neighborhoods. The longer your vehicles are on the road, the more opportunities you have to engage potential customers and build lasting impressions.
  To make it even more rewarding, we offer special discounts for longer campaigns. Enjoy exclusive savings on 3, 6, 9, and 12-month packages, giving you the best value while driving your brand’s success over time. Start seeing greater returns with a campaign designed for long-term growth!
    </p>
  </div>
</div>
<div className="collapse collapse-plus bg-white border-b rounded-none">
  <input type="radio" name="my-accordion-3" />
  <div className="collapse-title font-medium text-[22px] text-gray-700 text-left">
  How does digital retargeting work, and what information is required?
  </div>
  <div className="collapse-content">
  <p className="text-left text-lg max-w-[90%] opacity-70">
  Be Seen drivers log their campaign mileage through the Be Seen Driver App (using GPS). This location data is then used by our platform to track impressions and gather mobile Ad IDs (IDFA/AAID) from users within a specific radius of the campaign vehicles.
  </p>
  </div>
</div>


<div className="collapse collapse-plus bg-white border-b rounded-none">
  <input type="radio" name="my-accordion-3" />
  <div className="collapse-title font-medium text-[22px] text-gray-700 text-left">
  Where can we run a campaign?
  </div>
  <div className="collapse-content">
  <p className="text-left text-lg max-w-[90%] opacity-70">
  Be Seen operates across the entire country!
  </p>
  </div>
</div>

<div className="collapse collapse-plus bg-white border-b rounded-none">
  <input type="radio" name="my-accordion-3" />
  <div className="collapse-title font-medium text-[22px] text-gray-700 text-left">
  What is the cost of running a campaign?
  </div>
  <div className="collapse-content">
  <p className="text-left text-lg max-w-[90%] opacity-70">
  The cost of a Be Seen campaign depends on its size, reach, and objectives.
  </p>
  </div>
</div>

<div className="collapse collapse-plus bg-white border-b rounded-none">
  <input type="radio" name="my-accordion-3" />
  <div className="collapse-title font-medium text-[22px] text-gray-700 text-left">
  How do you maintain design consistency across various vehicle models?
  </div>
  <div className="collapse-content">
  <p className="text-left text-lg max-w-[90%] opacity-70">
  Our creative team collaborates closely with your brand or agency to ensure your design looks consistent across all types of vehicles. We also work with certified installers to ensure that your brand’s visuals are flawlessly applied to each vehicle.
  </p>
  </div>
</div>

<div className="collapse collapse-plus bg-white border-b rounded-none">
  <input type="radio" name="my-accordion-3" />
  <div className="collapse-title font-medium text-[22px] text-gray-700 text-left">
  Does the campaign include production, media, and installation?
  </div>
  <div className="collapse-content">
  <p className="text-left text-lg max-w-[90%] opacity-70">
  Yes, all aspects of production, media creation, and installation are included in every Be Seen campaign.
  </p>
  </div>
</div>





        </div>
        </div>
    )
}

export default FAQ;